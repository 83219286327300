.cta {
    position: relative;
    margin: auto;
    padding: 5px 15px;
    transition: all 0.2s ease;
    border: none;
    background: none;
}

.cta:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    border-radius: 7px;
    background: #b1dae7;
    width: 100%;
    height: 50%;
    transition: all 0.3s ease;
}

.cta span {
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.15em;
    color: var(--primary-color);
}

.cta:hover:before {
    width: 100%;
    height: 100%;
}

.cta:active {
    transform: scale(0.95);
}