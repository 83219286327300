.create-form-container {
    padding: 1rem; /* Tailwind's p-4 */
  }
  
  .form-title {
    text-align: center; /* Tailwind's text-center */
  }
  
  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* Tailwind's flex flex-col gap-4 */
  }
  
  .form-row {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    gap: 1rem;
    margin-top: 1rem; /* Tailwind's flex flex-col flex-1 w-full gap-4 mt-4 */
  }
  
  .md-flex-row {
    flex-direction: row; /* Tailwind's md:flex-row */
  }
  
  .input-grow {
    flex-grow: 1; /* Tailwind's grow */
  }
  
  .md-half-width {
    width: 50%; /* Tailwind's md:!w-[50%] */
  }
  
  .mt-5 {
    margin-top: 1.25rem; /* Tailwind's mt-5 */
  }
  
  .flex-gap-2 {
    display: flex;
    gap: 0.5rem; /* Tailwind's flex gap-2 */
  }

  .formLink{
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .width-full{
    width: 100%;
  }