/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
  scroll-behavior: smooth;
  --primary-color: #012970;
  --secondary-color: #4154f1;
}

* {
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  background: #f6f9ff;
  color: #444444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--secondary-color);
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: #717ff5;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin: auto;
  font-weight: bold !important;
  font-family: "Poppins", sans-serif !important;
}

th * {
  font-weight: bold;
  font-size: 1rem;
  white-space: nowrap;
  text-transform: initial;
  /* min-width: fit-content; */
}
.ant-picker-dropdown {
  z-index: 100000 !important;
}
.ant-image-preview-mask {
  background-color: rgba(255, 255, 255, 0.95) !important;
}

.ant-image-preview-img {
  width: 40% !important;
}

.ant-image-preview-operations,
.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  background: rgba(0, 0, 0, 0.9) !important;
}

.MuiTableCell-head .MuiButton-root {
  padding: 0px 6px !important;
}

.MuiImageListItem-img {
  object-fit: contain !important;
}
